app.controller('LanguageCtrl', ['$scope', '$window', '$translate', function($scope, $window, $translate){

    var self        = this;
    self.current    = 'English';
    self.language   = $window.localStorage.language || $window.lng;
    self.languages  = [
        {
            language: 'it',
            name: 'Italiano'
        },
        {
            language: 'en',
            name: 'English'
        },
        {
            language: 'de',
            name: 'Deutsch'
        },
        {
            language: 'es',
            name: 'Spanish'
        },
        {
            language: 'fr',
            name: 'Francais'
        },
        {
            language: 'cz',
            name: 'Cescky'
        },
        {
            language: 'pl',
            name: 'Polska'
        }
    ];

    if(typeof $window.localStorage.language === 'undefined')
    {
        $window.localStorage.language = $window.lng;
    }

    angular.forEach(self.languages, function(language){
        if(language.language == self.language) self.current = language.name;
    });

    this.setLanguage = function(lang)
    {
        self.current = lang.name;
        $translate.use(lang.language);
        $window.localStorage.language = lang.language;
    };

}]);
