app.filter('quantity', function(){
    return function (input, gate, filters) {
        var q = input.toString().substr(0, 1);

        if(q != 0){
            return input;
        }

        var len = input.toString().substr(-1);
        var totLength = (filters.opening + gate.e + gate.c + (2 * gate.d)) + filters.opening + (2 * gate.e);
        if(len == 6){
            return Math.round(totLength / len);
        } else {
            var qty = totLength / 6;
            var qtyRounded = Math.round(qty);

            if(qty > qtyRounded) return 1;
            return 0;
        }

    };
});
