app.directive("banner", ["$http", '$sce', function ($http, $sce) {
    return {
        restrict: "AE",
        replace: true,
        template: "<div class=\'banner\' data-ng-show=\'visible\'>\n    <div data-ng-bind-html=\'content\'></div>\n    <span class=\'close\' data-ng-click=\'visible = false\'>&times;</span>\n</div>",
        link: function (scope, element, attrs) {

            scope.content   = '';
            scope.visible   = false;

            $http.get('api/banner').then(function(res) {
                var body = res.data.body;
                body = $sce.trustAsHtml(body);
                scope.content = body;

                res.data.position = res.data.position || 2;

                switch (parseInt(res.data.position)) {
                    case 1: $(element).addClass('top'); break;
                    case 2: $(element).addClass('bottom'); break;
                }

                scope.visible = true;

            }, function() {
                scope.visible = false;
            });

        }
    }
}]);