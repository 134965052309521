app.directive("slider", ['$timeout', function($timeout) {

    return {
        restrict: "AEC",
        require: "?ngModel",
        scope: {
            liveOptions: '=',
            ngModel: '='
        },
        link: function(scope, element, attrs) {

            var change = function(event, ui) {
                scope.$apply(function() {
                    if (typeof ui.values !== 'undefined') {
                        scope.ngModel = ui.values;
                    } else {
                        scope.ngModel = ui.value;
                    }
                });
            };

            var options = scope.$eval(attrs.options) || {};


            if (typeof scope.ngModel !== 'undefined') {
                var value = scope.ngModel;

                if (Array.isArray(value)) {
                    options.values = value;
                } else {
                    options.value = value;
                }

                options.change = change;

                element.slider(options);
            } else {
                element.slider(options);
            }


            scope.$watch('liveOptions', function(n, o) {
                if (typeof n !== 'undefined' && n !== null) {
                    element.slider("option", n);
                }
            });

            scope.$watch('ngModel', function(n, o) {
                if (n !== element.slider("values")) {

                    element.slider({
                        change: null
                    });

                    element.slider("value", n);

                    element.slider({
                        change: change
                    });
                }
            });

        }
    };
}]);
