app.controller('OptionsCtrl', ['$scope', 'Gate', '$state', '$window', '$filter', function($scope, Gate, $state, $window, $filter){
    $scope.Gate = Gate;

    if(!$scope.Gate.gates.length){
        var gatesRAW = $window.localStorage.gates;
        var filterRAW = $window.localStorage.filters;

        if(typeof gatesRAW === 'string'){
            $scope.Gate.gates = JSON.parse(gatesRAW);

            if(typeof filterRAW === 'string'){
                $scope.Gate.filter = JSON.parse(filterRAW);
            }
        } else {
            $state.go('filter');
        }
    }

    $scope.checkQty = function(value) {
        if(value.qty > 0) {
            return true;
        }
        return false;
    };

}]);
