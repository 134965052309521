app.controller('SendCtrl', ['$scope', '$http', 'Gate', function($scope, $http, Gate){

    $scope.Gate = Gate;
    $scope.data = {};

    $scope.submit = function()
    {
        $scope.sending = true;
        $scope.data.permalink = $scope.Gate.permalink;
        $scope.data.filters = $scope.Gate.filter;

        console.log($scope.data);

        $http.post('api/notifications/send', $scope.data).
            success(function(data, status, headers, config) {
                $scope.sending = false;

                if(!data.success){
                    alert('Unable to send configuration! Try again later');
                    return;
                }

                alert('Configuration sent successfully!');
                $scope.data = {};
            }).
            error(function(data, status, headers, config) {
                $scope.sending = false;
            });
    };

}]);
