app.controller('SpecialCtrl', ['$scope', '$http', 'Gate', function($scope, $http, Gate){

    $scope.data = {};
    $scope.Gate = Gate;

    $scope.submit = function()
    {
        $scope.sending = true;

        $scope.data.permalink = $scope.Gate.permalink;
        $scope.data.gates = Gate.gates;
        $scope.data.filters = Gate.filter;
        $scope.data.options = Gate.options;
        $scope.data.selected = Gate.selected;

        $http.post('api/notifications/special', $scope.data).
            success(function(data, status, headers, config) {
                $scope.sending = false;

                if(!data.success){
                    alert('Unable to send request! Try again later');
                    return;
                }

                alert('Request sent successfully!');
                $scope.data = {};
            }).
            error(function(data, status, headers, config) {
                $scope.sending = false;
            });
    };

    $scope.$watch('Gate.notes', function(n,o){
        if(n == o) return;
        $scope.data.note = n;
    });

}]);
