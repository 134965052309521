app.directive('openModal', function(){
    return {
        restrict: 'A',
        link: function(scope, element, attr){
            element.click(function(){
                $('#' + attr.openModal).modal('show');
            });
        }
    };
});
