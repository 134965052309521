app.factory('AuthService', ['$http', '$q', '$rootScope', 'jwtHelper', function($http, $q, $rootScope, jwtHelper) {

    let Auth = function (data) {
        _.merge(this, data);
    };

    /**
     * Carica l'utente dal localstorage
     */
    Auth.load = function() {
        var token   = window.localStorage.getItem('token');

        if(!token) {
            throw new Error('Token assente');
        }

        var user = jwtHelper.decodeToken(token);

        if(user) {
            // user = JSON.parse(user);
            return user;
        }

        return false;
    };

    Auth.login = function (username, password) {
        var url = 'api/auth/login';

        var data = {
            username: username,
            password: password,
            language: window.localStorage.language || window.lng
        };
        return $http.post(url, data).then(function success(response) {

            var user = new Auth(jwtHelper.decodeToken(response.data.token));
            $rootScope.loggedUser = user;

            window.localStorage.setItem('token', response.data.token);

            return user;
        }).catch(function error(error) {
            return $q.reject(error);
        });
    }

    Auth.logout = function () {
        delete $rootScope.loggedUser;
        window.localStorage.removeItem('token');
        window.sessionStorage.clear();
    };


    Auth.getToken = function() {
        return window.localStorage.getItem('token');
    };

    return Auth;
}]);