app.controller('FilterCtrl', ['$scope', 'Gate', '$translate', function($scope, Gate, $translate){
    $scope.Gate = Gate;

    angular.forEach($scope.Gate.gates, function(gate) {
        gate.products = [];
    });


    Gate.getXXLDescription().then(function(desc) {
        $scope.xxlDescription = desc;
    });


    $scope.openSpecial = function() {
        Gate.notes = $translate.instant('LATERAL_FORCES_REQUEST_NOTES');
    }

}]);
