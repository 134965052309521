var app = angular.module('himotions', ['ui.router', 'pascalprecht.translate', 'angular-jwt']);

window.lng = 'en';
window.localStorage.language = 'en';

app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$translateProvider', function config($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider) {

    $translateProvider.useStaticFilesLoader({
        prefix: 'localizations/locale-',
        suffix: '.json'
    });
    $translateProvider.preferredLanguage(window.localStorage.language || window.lng);


    $locationProvider.html5Mode(true).hashPrefix('!');

    //$stateProvider.state("home", {
    //    url: "/",
    //    templateUrl: "partials/home.html",
    //    controller: "HomeCtrl"
    //});

    $stateProvider.state("filter", {
        url: "/?t&m&o&k",
        templateUrl: "partials/filter.html",
        controller: "FilterCtrl"
    });

    $stateProvider.state("options", {
        url: "/options",
        templateUrl: "partials/options.html",
        controller: "OptionsCtrl"
    });

}]);


app.run(['$rootScope', 'AuthService', function($rootScope, AuthService){
    $rootScope.logout = function () {
        AuthService.logout();
    };

    try {
        $rootScope.loggedUser = AuthService.load();
    } catch (e) {
        $rootScope.logout();
    }


    $rootScope.$on("$stateChangeSuccess", function(event, toState, toParams, fromState, fromParams){
        $rootScope._state = toState.name;
    });
}]);
