app.directive("printheader", function(){
    return {
        restrict: "E",
        templateUrl: "partials/printheader.html",
        replace: true,
        link: function(scope, element, attrs) {

        }
    };
});
