app.factory('Gate', ["$http", "$q", "$rootScope", "$timeout", "$state", "$stateParams", "$window", function($http, $q, $rootScope, $timeout, $state, $stateParams, $window){
    var canceler = $q.defer();

    var self = {
        gates: [],
        filter: {
            tail: ($stateParams.t ? parseInt($stateParams.t) : null),
            materials: ($stateParams.mt ? $stateParams.mt : [{checked:true}, {checked:true}]),
            mounting: ($stateParams.m ? parseInt($stateParams.m) : null),
            opening: ($stateParams.o ? +parseFloat($stateParams.o).toFixed(1) : 40),
            weight: ($stateParams.k ? parseInt($stateParams.k) : 1000)
        },
        options: {},
        selected: null,
        loadingGates: false,
        loadingProducts: false,
        permalink: null
    };

    $timeout(function(){
        self.filter.tail = ($stateParams.t ? parseInt($stateParams.t) : null);
        self.filter.materials = ($stateParams.mt ? $stateParams.mt : [{checked:true}, {checked:true}]);
        self.filter.mounting = ($stateParams.m ? parseInt($stateParams.m) : null);
        self.filter.opening = ($stateParams.o ? +parseFloat($stateParams.o).toFixed(1) : 40);
        self.filter.weight = ($stateParams.k ? parseInt($stateParams.k) : 1000);
    }, 500, true);

    self.generatePermalink = function()
    {
        if('filter' != $state.current.name) return;

        $state.go($state.current.name, {
            mt: self.filter.materials,
            t: self.filter.tail,
            m: self.filter.mounting,
            o: self.filter.opening,
            k: self.filter.weight
        }, {
            notify: false,
            reload: false
        });

        $timeout(function(){
            self.permalink = $window.location.href;
        }, 500, true);
    };


    self.getGates = function()
    {
        if(canceler) {
            canceler.resolve("cancelled"); // Resolve the previous canceler
        }

        canceler = $q.defer(); // Important: Create a new canceler!

        // if(self.filter.tail === null || self.filter.mounting == null) return;

        self.generatePermalink();
        self.loadingGates = true;

        var config = {
            params: {materials: JSON.stringify(self.filter.materials)},
            cache: false,
            timeout: canceler.promise
        };

        $http.get('api/gates/gates/' + self.filter.opening + '/' + self.filter.mounting + '/' + self.filter.weight + '/' + self.filter.tail, config).
            success(function(data, status, headers, config) {
                self.gates = data;

                angular.forEach(self.gates, function(g, k) {
                    g.l = self.filter.opening + g.c + g.e + (g.d * 2); // lunghezza totale
                    g.pm = g.l * g.pr; // peso monorotaia
                    if(self.filter.tail == 1) {
                        g.gw = ((self.filter.weight / self.filter.opening) * g.l) ; // PC -> peso cancello
                    } else {
                        g.gw = ((self.filter.weight / self.filter.opening) * ((g.l + self.filter.opening) /2)) ; // PC -> peso cancello
                    }
                    g.p = g.pm + g.gw;
                });

                self.loadingGates = false;
            }).
            error(function(data, status, headers, config) {
                if(data) {
                    self.loadingGates = false;
                }
            });
    };
    self.getGates();

    self.getProducts = function()
    {
        if(typeof self.options.ruotino === 'undefined' || !typeof self.options.ruotino) return;

        var gates = [];
        angular.forEach(self.gates, function(gate){
            gates.push(gate.id);
        });

        if(!gates.length){
            self.options = {};
            $state.go('filter');
            return;
        }

        self.loadingProducts = true;
        var incontro = (typeof self.options.incontro === 'undefined' || self.options.incontro === null) ? 3 : self.options.incontro;
        var section = self.options.section ? "/" + self.options.section : '';
        var sectionSize = self.options.sectionSize ? "/" + self.options.sectionSize : '';

        $http.get('api/gates/products/' + gates.join('-') + '/' + self.options.ruotino + '/' + incontro + section + sectionSize ).
            success(function(data, status, headers, config) {

                angular.forEach(data, function(g){
                    angular.forEach(self.gates, function(gate){
                        if(gate.id == g.id){
                            gate.products = g.products;

                            // calcolo quantità
                            angular.forEach(gate.products, function(product, j){
                                var input = product.qty;
                                var q = input.toString().substr(0, 1);

                                if(q != 0){
                                    return;
                                }

                                var len = input.toString().substr(-1) * 10;
                                var totLength = self.filter.opening + gate.e + gate.c + (2 * gate.d);
                                if(len == 20){
                                    product.qty = Math.round(totLength / len);
                                } else {
                                    var qty = totLength / 20;
                                    var qtyRounded = Math.round(qty);

                                    if(qty > qtyRounded) {
                                        product.qty =  1;
                                    } else {
                                        product.qty = 0;
                                    }
                                }
                            });

                            return;
                        }
                    });
                });

                self.loadingProducts = false;
            }).
            error(function(data, status, headers, config) {
                self.loadingProducts = false;
            });
    };

    self.int = function(number)
    {
        return parseInt(number);
    };

    self.setSelected = function(index)
    {
        self.selected = index;
        $window.localStorage.selected = index;
        $window.localStorage.gates = JSON.stringify(self.gates);
        $window.localStorage.filters = JSON.stringify(self.filter);

        self.options = {};

        $state.go('options');
    };

    self.getSelected = function()
    {
        return self.selected || $window.localStorage.selected;
    };

    $rootScope.$watch(function () {
        return self.filter.materials.map(function(checked) {
            return checked.checked;
        });
    }, function watchCallback(n, o) {
        if (n !== o) self.getGates();
    }, true);

    $rootScope.$watch(function () {
        return self.filter.tail;
    }, function watchCallback(n, o) {
        if (n !== o) self.getGates();
    });

    $rootScope.$watch(function () {
        return self.filter.opening;
    }, function watchCallback(n, o) {
        if (n !== o) self.getGates();
    });

    $rootScope.$watch(function () {
        return self.filter.weight;
    }, function watchCallback(n, o) {
        if (n !== o) self.getGates();
    });

    $rootScope.$watch(function () {
        return self.filter.mounting;
    }, function watchCallback(n, o) {
        if (n !== o) self.getGates();
    });


    self.getXXLDescription = function() {

        return $http.get('api').then(function(res) {
            return res.data.description;
        }, function(res) {

        });
    };

    return self;

}]);
