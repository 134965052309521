app.directive('tip', function(){
    return {
        restrict: 'C',
        link: function (scope, element, attr) {
            var options = scope.$eval(attr.options);
            element.tooltip(options);
        }
    };
});

app.directive('pop', function(){
    return {
        restrict: 'C',
        link: function (scope, element, attr) {
            var options = scope.$eval(attr.options);
            element.popover(options);
        }
    };
});
