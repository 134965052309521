app.controller('AuthCtrl', ["$scope", "$http", "$rootScope", "$stateParams", "$state", "AuthService", function($scope, $http, $rootScope, $stateParams, $state, AuthService) {
    $rootScope.title = "AngularJS Login Sample";

    $scope.username = '';
    $scope.password = '';
    $scope.loading = false;

    $scope.login = function() {
        $scope.loading = true;
        $scope.error = null;

        AuthService.login(this.username, this.password).then(function() {
            $("#user-auth .dropdown-toggle").dropdown('toggle');
        }, function(error) {
            $scope.error = error.data.message;
        }).finally(function () {
            $scope.loading = false;
        })
    };

    $scope.logout = function () {
        AuthService.logout();
    }
}]);